<template>
  <TModal
    title="Create"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <span></span>
    </template>
    <CreateAffiliateProduct
      :affiliateStoreId="affiliateStoreId"
      @created="$emit('created')"
      @update:show="$emit('update:show', $event)"
    />
  </TModal>
</template>

<script>
import CreateAffiliateProduct from "../CreateAffiliateProduct.vue";
export default {
  components: { CreateAffiliateProduct },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    affiliateStoreId: {
      type: [String, Number],
      required: false,
    },
  },
};
</script>
