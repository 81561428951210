<template>
  <TModal
    :title="is_edit ? 'Category' : 'Create category'"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :creating="loading"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
        v-if="!is_edit"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit"
        :options="{ disabled: loading }"
      />
    </template>
    <SSelectAffiliateStore
      v-if="!affiliateStoreId"
      label="Affiliate store"
      @update:value="
        input.affiliate_store_id = $event;
        affiliate_store_id = $event;
      "
      @change="input.parent_id = ''"
      :value="input.affiliate_store_id"
      class="mb-3"
      prependAll
      :prepend="{ id: '', name: $t('Select') }"
      :selectOptions="{ disabled: is_edit }"
    />
    <TInputText
      label="Id"
      class="mb-3"
      @update:value="input.category_id = $event"
      :value="input.category_id"
      :disabled="is_edit"
    />
    <TInputText
      label="Name"
      class="mb-3"
      :value="input.name"
      @update:value="input.name = $event"
    />
    <SSelectAffiliateCategory
      :value.sync="input.parent_id"
      label="Parent category"
      :prepend="{ id: '', name: $t('Select') }"
      prependAll
      @update:value="input.parent_id = $event"
      :selectOptions="{
        disabled: !affiliate_store_id && !affiliateStoreId,
      }"
      :affiliateStoreId="affiliateStoreId || input.affiliate_store_id"
      store="category_affiliate_parent_list"
    />
    <TMessage content="Featured image" class="mt-3 mb-2" bold />
    <CImg
      style="height: 150px; width: 150px; object-fit: contain"
      thumbnail
      fluidGrow
      :src="image ? image : image_url"
      placeholderColor
      class="mb-3 p-0"
    />
    <TInputFile
      :value.sync="file"
      :accept="'image/*'"
      @update:value="onFileChange($event)"
      class="w-100"
      :key="fileKey"
      :multiple="false"
    />
    <TInputText
      :value.sync="image_url"
      @update:value="imgUrlChange"
      class="w-100 mt-3"
      placeholder="Enter image url"
      :inputOptions="{ prepend: 'URL' }"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Object,
      required: false,
    },
    affiliateStoreId: {
      type: [String, Number],
      required: false,
    },
  },
  inputDefault: {
    id: "",
    category_id: "",
    name: "",
    parent_id: "",
  },
  data() {
    return {
      options: [
        { value: "user", label: "Customer" },
        { value: "supplier", label: "Supplier" },
      ],
      loading: false,
      data: this.getInput(),
      key: "key",
      disabled: false,
      affiliate_store_id: "",
      image_url: "",
      image: "",
      file: [],
      fileKey: "file-key",
    };
  },
  watch: {
    category(cat) {
      this.data = this.getInput();
      this.image = cat.image_url;
      this.file = [];
      this.image_url = "";
    },
    // affiliate_store_id() {
    //   this.input.parent_id = "";
    // },
  },
  computed: {
    is_edit() {
      if (!this.category) {
        return false;
      }
      return !!this.category.id;
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  methods: {
    create() {
      this.loading = true;

      let data = {
        image_file: this.file.length ? this.file[0].file : undefined,
        image_url: this.image_url ? this.image_url : undefined,
        ...this.input,

        affiliate_store_id: this.affiliateStoreId
          ? this.affiliateStoreId
          : this.input.affiliate_store_id,
      };
      this.$store
        .dispatch("product.category_affiliates.create", {
          ...this.lodash.pickBy(data),
        })
        .then((data) => {
          this.input = this.inputDefault();
          this.$emit("update:show", false);
          this.$emit("created", data);
          this.reset();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      let data = {
        category_id:
          this.input.category_id != this.category.category_id
            ? this.input.category_id
            : "",
        image_file: this.file.length ? this.file[0].file : "",
        image_url: this.image_url ? this.image_url : "",
        id: this.input.id != this.category.id ? this.input.id : "",
        name: this.input.name != this.category.name ? this.input.name : "",
      };
      this.$store
        .dispatch("product.category_affiliates.detail.update", {
          ...this.lodash.pickBy(data),
          parent_id:
            this.input.parent_id == this.category.parent_id
              ? this.category.parent_id || ""
              : this.input.parent_id || "",
        })
        .then((data) => {
          if (data.name !== this.category.name) {
            this.$store.dispatch(
              "product.category_affiliate_parent_list.fetch"
            );
          }
          this.$emit("update:show", false);
          this.$emit("updated", data);
        });
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.key = this.lodash.resetKey("key");
    },
    onFileChange(file) {
      if (this.file.length == 1) {
        this.image = URL.createObjectURL(file[0].file);
      } else {
        this.image = "";
      }
      this.image_url = "";
    },
    imgUrlChange(e) {
      this.file = [];
      this.image = "";
      this.image_url = e;
      this.fileKey = this.lodash.resetKey("file-key");
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return { ...this.category };
    },
  },
};
</script>
