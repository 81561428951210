<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap align-items-center">
      <TMessage :content="detail.name" bold noTranslate class="ml-2" />
      <TSpinner :loading="loading" />
      <TButton
        content="Add banner"
        @click="showBannerModal = true"
        class="ml-auto"
        icon="cilScaleImage"
        variant="outline"
        color="info"
      />
    </CCardHeader>
    <CCardBody class="d-flex flex-wrap">
      <CCol col="12" lg="12" xl="12">
        <CRow>
          <CCol :lg="{ size: 4, order: 1 }" md="6" sm="12" class="my-2">
            <TMessage content="Name" color="primary" bold>
              <template #prefix>
                <CIcon name="cil-user" class="my-auto mr-2" />
              </template>
            </TMessage>
            <TMessageText
              :value="detail.name"
              class="mt-2"
              :editable="!loading"
              @change="updateAS('name', $event)"
            />
          </CCol>
          <CCol :lg="{ size: 4, order: 2 }" md="6" sm="12" class="my-2">
            <TMessage content="Address" color="primary" bold>
              <template #prefix>
                <CIcon name="cilMail" class="my-auto mr-2" />
              </template>
            </TMessage>
            <TMessageText
              :value="detail.address"
              class="mt-2"
              :editable="!loading"
              @change="updateAS('address', $event)"
            />
          </CCol>
          <CCol :lg="{ size: 4, order: 3 }" md="6" sm="12" class="my-2">
            <TMessage content="Tel" color="primary" bold>
              <template #prefix>
                <CIcon name="cilPhone" class="my-auto mr-2" />
              </template>
            </TMessage>
            <TMessageText
              :value="detail.tel"
              class="mt-2"
              :editable="!loading"
              @change="updateAS('tel', $event)"
            />
          </CCol>
          <CCol :lg="{ size: 4, order: 4 }" md="6" sm="12" class="my-2">
            <TMessage content="Type" color="primary" bold>
              <template #prefix>
                <CIcon name="cil-view-list" class="my-auto mr-2" />
              </template>
            </TMessage>
            <TMessageText
              :value="detail.type"
              class="mt-2"
              :editable="!loading"
              @change="updateAS('type', $event)"
            />
          </CCol>
          <CCol :lg="{ size: 4, order: 5 }" md="6" sm="12" class="my-2">
            <TMessage content="Product image" color="primary" bold>
              <template #prefix>
                <CIcon name="cil-chart-table" class="my-auto mr-2" />
              </template>
            </TMessage>
            <TMessageText
              :value="detail.img_store"
              class="mt-2"
              :editable="!loading"
              @change="updateAS('img_store', $event)"
            />
          </CCol>

          <CCol :lg="{ size: 4, order: 6 }" md="6" sm="12" class="my-2">
            <TMessage content="Ecommerce channel" color="primary" bold>
              <template #prefix>
                <CIcon name="cilGlobe" class="my-auto mr-2" />
              </template>
            </TMessage>
            <SMessageProductEc
              :id="detail.ecommerce_channel_id"
              :editable="!loading"
              @change="updateAS('ecommerce_channel_id', $event)"
            />
          </CCol>
        </CRow>
      </CCol>
      <AffiliateStoreBannerModal
        :show.sync="showBannerModal"
        :images="detail.images"
      />
      <CCol>
        <hr />
        <TMessage content="Banner" noTranslate bold />
        <CRow>
          <CCol col="6" v-for="image in detail.images" :key="image.id">
            <TImg
              class="p-0 m-1"
              style="height: 100px; object-fit: contain"
              showFull
              :src="image.url ? image.url : ''"
              @click-delete="handleDeleteBanner(image.id)"
              deletable
            />
          </CCol>
        </CRow>
        <TModalConfirm
          :show.sync="showModalDelete"
          @click-confirm="onDeleteImg(banner_id_seleted)"
        />
        <TMessageNotFound v-if="detail.images && !detail.images.length" />
      </CCol>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
import AffiliateStoreBannerModal from "./AffiliateStoreBannerModal";
export default {
  components: { AffiliateStoreBannerModal },
  data() {
    return {
      showBannerModal: false,
      showModalDelete: false,
      fileKey: "file-key",
      file: [],
      banner_id_seleted: "",
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("product.affiliate_stores.detail.fetch", this.id);
    }
  },
  computed: {
    ...mapGetters({
      detail: "product.affiliate_stores.detail",
      loading: "product.affiliate_stores.detail.loading",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    updateAS(field, value) {
      return this.$store.dispatch("product.affiliate_stores.detail.update", {
        [field]: value,
      });
    },
    handleDeleteBanner(id) {
      this.showModalDelete = true;
      this.banner_id_seleted = id;
    },
    onDeleteImg(id) {
      this.$store.dispatch("product.affiliate_stores.detail.detach-banner", {
        "affiliate_store_image_ids[]": id,
      });
    },
  },
};
</script>
