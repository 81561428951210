<template>
  <TModal
    title="Add banner"
    :show="show"
    @update:show="$emit('update:show', $event)"
    :key="key"
    size="lg"
  >
    <template #actions>
      <TButtonAdd
        variant="outline"
        @click="onAttachImg"
        :options="{ disabled: disabled }"
      />
    </template>
    <TInputFile
      :value.sync="file"
      :accept="'image/*'"
      class="w-100 mt-2"
      :key="fileKey"
      :multiple="true"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      file: [],
      key: "key",
      disabled: false,
      fileKey: "file-key",
    };
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.key = this.lodash.resetKey("key");
    },
    onAttachImg() {
      let data = {};
      this.file.forEach((x, i) => {
        const item = {
          [`images[${i}][file]`]: x.file,
          [`images[${i}][type]`]: "primary",
        };
        data = { ...data, ...item };
      });
      this.$store
        .dispatch(
          "product.affiliate_stores.detail.attach-banner",
          this.lodash.pickBy({
            ...data,
          })
        )
        .then(() => {
          this.reset();
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
  },
};
</script>
