<template>
  <TTabs :tabs="tabs" @change-tab="$router.push({ query: { tab: $event } })" :activeTab="$route.query.tab">
    <template #info>
      <AffiliateInfo />
    </template>
    <template #category>
      <AffiliateCategory />
    </template>
    <template #product>
      <AffiliateProduct :affiliateStoreId="id" />
    </template>
  </TTabs>
</template>
<script>
import AffiliateProduct from "./components/AffiliateProduct.vue";
import AffiliateInfo from "./components/AffiliateInfo.vue";
import AffiliateCategory from "./components/AffiliateCate.vue";

export default {
  components: { AffiliateInfo, AffiliateCategory, AffiliateProduct },
  data() {
    return {
      tabs: [
        { key: "info", name: "Info", icon: "cisUser" },
        {
          key: "category",
          name: "Category",
          icon: "cisTruck",
        },
        {
          key: "product",
          name: "Product",
          icon: "cisListRich",
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  created() {
    // this.$store.dispatch("product.affiliate_stores.detail.fetch", this.id);
  },
};
</script>
