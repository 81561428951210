<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="Product" bold />
      <TSpinner :loading="loading" />
      <TButton
        content="Create product"
        color="info"
        icon="cilPlus"
        @click="showModalAddProduct = true"
        class="ml-auto"
      />
    </CCardHeader>
    <CCardBody>
      <TTableAdvance
        :items="list"
        :fields="fields"
        store="product.product_affiliate_store_list"
        @click-clear-filter="clearFilter"
        resource="/admin/ecommerce/affiliate-product"
        enterable
        reloadable
        deletable
        @click-reload="fetch"
      >
        <template #image_url="{ item }">
          <td>
            <SCardProductItems
              :item="{
                image_url: item.image_url,
                name: item.name,
                id: item.product_id,
              }"
              widthAuto
            />
          </td>
        </template>
        <template #price="{ item }">
          <td>
            <TMessageMoney
              :amount="item.price"
              :currency="item.currency_id || 'JPY'"
            />
          </td>
        </template>
        <template #product_id="{ item }">
          <td class="text-break">
            <TMessage :content="item.product_id" noTranslate />
          </td>
        </template>
        <template #initial_price="{ item }">
          <td>
            <TMessageMoney
              :amount="item.initial_price"
              :currency="item.currency_id || 'JPY'"
            />
          </td>
        </template>
        <template #delivery="{ item }">
          <td>
            <TMessage
              :content="
                item.delivery == 'free_delivery'
                  ? 'Free delivery'
                  : item.delivery == 'anonymous_delivery'
                  ? 'Anonymous delivery'
                  : 'Empty'
              "
            />
          </td>
        </template>
        <template #categories="{ item }">
          <td>
            <CBadge
              color="primary"
              v-for="cat in item.categories"
              :key="cat.id"
              class="mr-1"
            >
              {{ cat.name }}
            </CBadge>
            <TMessageNotFound
              v-if="item.categories && !item.categories.length"
            />
          </td>
        </template>

        <template #url="{ item }">
          <td>
            <TLink :content="item.url" :to="item.url" />
          </td>
        </template>
        <template #product_id-filter>
          <TInputText
            :value.sync="filter.product_id"
            @update:value="filterChange"
            placeholder="Product Id"
          />
        </template>
        <template #image_url-filter>
          <TInputText
            :value.sync="filter.name"
            @update:value="filterChange"
            placeholder="Product name"
          />
        </template>
      </TTableAdvance>
      <AddProductModal
        :affiliateStoreId="affiliateStoreId"
        :show.sync="showModalAddProduct"
        @created="filterChange"
      />
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
import AddProductModal from "./AddProductModal.vue";
export default {
  components: { AddProductModal },
  props: {
    affiliateStoreId: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      showModalAddProduct: false,
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "product_id", label: "Product Id", _style: "min-width: 150px" },
        { key: "image_url", label: "Name", _style: "min-width: 350px" },
        { key: "price", label: "Price", _style: "min-width: 200px" },
        {
          key: "initial_price",
          label: "Initial price",
          _style: "min-width: 200px",
        },
        {
          key: "categories",
          label: "Category",
          _classes: "text-nowrap",
          _style: "width: 200px; min-width: 200px",
        },
        { key: "delivery", label: "Delivery type", _style: "min-width: 200px" },
        { key: "url", label: "Link store", _style: "min-width: 200px" },
      ],
      filter: {},
      prepend: [{ id: "", name: this.$t("All") }],
    };
  },
  created() {
    if (this.id) {
      this.fetch();
    }
  },
  computed: {
    ...mapGetters({
      list: "product.product_affiliate_store_list.list",
      loading: "product.product_affiliate_store_list.loading",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch("product.product_affiliate_store_list.apply-query", {
        "filter[affiliate_store_id]": this.id,
      });
    },
    filterChange() {
      const filter = this.lodash.mapKeys(
        { ...this.filter },
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
      });
      this.$store.dispatch("product.product_affiliate_store_list.apply-query", {
        "filter[affiliate_store_id]": this.id,
        ...filterFields,
      });
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
    removeItem(id) {
      this.$store.dispatch("product.product_affiliate_store_list.delete", id);
    },
  },
};
</script>
