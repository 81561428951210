<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex flex-wrap align-items-center">
        <TMessage :content="is_edit ? 'Detail' : 'New product'" bold />
        <div class="d-flex ml-auto">
          <TButtonClear
            v-if="!is_edit"
            variant="outline"
            class="mr-2"
            @click="reset"
            :options="{ iconSize: 16 }"
          />
          <TButton
            v-if="is_editing"
            content="Add category"
            variant="outline"
            icon="cil-plus"
            class="ml-auto"
            @click="showModalAddCategory = true"
          />
          <TButton
            v-if="is_editing"
            content="Add tag"
            variant="outline"
            icon="cil-plus"
            class="ml-2"
            @click="showModalAddProductTag = true"
          />
          <TButton
            v-if="!is_edit"
            icon="cil-plus"
            content="Create product"
            class="font-weight-bold"
            @click="create"
            :options="{
              disabled: disabled || product.price > product.initial_price,
            }"
          />
          <TButton
            v-if="is_edit && !is_editing"
            icon="cil-pencil"
            content="Edit"
            class="font-weight-bold ml-2"
            @click="is_editing = true"
            color="info"
            :options="{ disabled: disabled }"
          />
          <TButton
            v-if="is_edit && is_editing"
            icon="cil-check"
            content="Save"
            class="font-weight-bold ml-2"
            @click="save"
            :options="{ disabled: disabled }"
          />
          <AddProductCategory
            v-if="is_edit"
            :show.sync="showModalAddCategory"
            :affiliate_store_id="detail.affiliate_store_id"
          />
          <AddProductTag v-if="is_edit" :show.sync="showModalAddProductTag" />
        </div>
      </CCardHeader>
      <CCardBody :key="formKey">
        <CRow class="mb-2">
          <CCol col="12" lg="6">
            <CRow>
              <CCol col="12" lg="12">
                <TInputText
                  placeholder="Product Id"
                  label="Product Id"
                  class="mb-3"
                  @update:value="product.product_id = $event"
                  :value="detail.product_id"
                  :disabled="is_edit"
                />
              </CCol>

              <CCol col="12" lg="12">
                <TInputText
                  placeholder="Product name"
                  label="Product name"
                  class="mb-3"
                  :disabled="!is_editing && is_edit"
                  @update:value="product.name = $event"
                  :value="detail.name"
                />
              </CCol>
            </CRow>

            <CRow>
              <CCol col="12" lg="6">
                <TInputMoney
                  label="Regular price"
                  class="mb-3"
                  @update:value="product.price = $event"
                  :value="detail.price"
                  :disabled="!is_editing && is_edit"
                />
              </CCol>
              <CCol col="12" lg="6">
                <TInputMoney
                  label="Initial price"
                  class="mb-3"
                  @update:value="product.initial_price = $event"
                  :value="detail.initial_price"
                  :disabled="!is_editing && is_edit"
                />
              </CCol>
              <CCol col="12" lg="12" class="mb-2">
                <TMessage
                  v-if="product.price > product.initial_price"
                  color="danger"
                  bold
                  :disabled="!is_editing"
                  content="Giá bán không được thấp giá gốc"
                />
              </CCol>
              <CCol col="12" lg="12">
                <TInputMoney
                  label="Percentage off"
                  class="mb-3"
                  disabled
                  :inputOptions="{ disabled: true }"
                  :maskOptions="{ integerLimit: 3 }"
                  :value="
                    detail.discount_percent
                      ? detail.discount_percent
                      : Math.floor(
                          ((product.initial_price - product.price) /
                            product.initial_price) *
                            100
                        )
                  "
                />
              </CCol>
            </CRow>
            <TInputNumber
              label="Product ratio"
              class="mb-3"
              @update:value="product.rate = $event"
              :maskOptions="{ integerLimit: 2 }"
              :value="detail.rate"
              :inputOptions="{ disabled: !is_editing && is_edit }"
            />
            <TInputText
              placeholder="Link store"
              label="Link store"
              class="mb-3"
              @update:value="product.url = $event"
              :value="detail.url"
              :disabled="!is_editing && is_edit"
            />
            <SSelectDeliveryTypeJp
              placeholder="Delivery type"
              label="Delivery type"
              class="mb-3"
              @update:value="product.delivery = $event"
              :value="detail.delivery"
              :selectOptions="{ disabled: !is_editing && is_edit }"
            />
            <SSelectAffiliateStore
              v-if="!is_edit && !affiliateStoreId"
              label="Affiliate store"
              @update:value="product.affiliate_store_id = $event; category_ids = ''"
              :value="detail.affiliate_store_id"
              class="mb-3"
              :prepend="{ id: '', name: $t('Select') }"
              prependAll
            />
            <div v-if="!!is_edit">
              <TInputText
                placeholder="Product Id"
                label="Affiliate store"
                class="mb-3"
                :value="
                  detail.affiliate_store ? detail.affiliate_store.name : ''
                "
                :disabled="is_edit"
              />
            </div>
            <SSelectAffiliateCategory
              v-if="!is_edit"
              label="Category"
              @update:value="category_ids = $event"
              class="mb-3"
              :prepend="{ id: '', name: $t('Select') }"
              prependAll
              :selectOptions="{
                disabled: !product.affiliate_store_id && !affiliateStoreId,
              }"
              :value.sync="category_ids"
              :affiliateStoreId="product.affiliate_store_id"
              store="category_affiliate_parent_list"
            />
          </CCol>
          <CCol col="12" lg="6" class="mb-3">
            <TMessage content="Featured image" class="mb-2" bold />
            <CImg
              style="height: 300px; object-fit: contain"
              thumbnail
              fluidGrow
              :src="image_url"
              placeholderColor
              class="mb-3 p-0"
            />
            <TInputText
              :value.sync="image_url"
              @update:value="imgUrlChange"
              class="w-100 mt-2"
              placeholder="Enter image url"
              :inputOptions="{ prepend: 'URL' }"
              :disabled="!is_editing && is_edit"
            />
            <div v-if="is_edit">
              <TMessage content="Category" class="mt-5 mb-2" bold>
                <template #suffix>:</template>
              </TMessage>
              <div
                v-if="detail.categories && detail.categories.length"
                class="d-flex flex-wrap"
              >
                <SMessageProductCategory
                  v-for="category in detail.categories"
                  :key="category.id"
                  class="mr-1 mb-1"
                  :category="category"
                  :removable="!disabled && is_editing"
                  store="product.product_affiliates"
                />
              </div>
              <TMessageNotFound v-else />
              <TMessage content="Tag" class="mb-2 mt-3" bold>
                <template #suffix>:</template>
              </TMessage>
              <div
                v-if="detail.tags && detail.tags.length"
                class="d-flex flex-wrap"
              >
                <SMessageProductTag
                  v-for="tag in detail.tags"
                  :key="tag.id"
                  class="mr-1 mb-1"
                  :tag="tag"
                  store="product.product_affiliates"
                  :removable="!disabled && is_editing"
                />
              </div>
              <TMessageNotFound v-else />
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import AddProductCategory from "./components/AddProductCategory.vue";
import AddProductTag from "./components/AddProductTag.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    AddProductCategory,
    AddProductTag,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    affiliateStoreId: {
      type: [String, Number],
      default: "",
    },
  },
  inputDefault: {
    product_id: "",
    name: "",
    price: 0,
    initial_price: 0,
    rate: 0,
    delivery: "",
    url: "",
    affiliate_store_id: "",
    image_url: "",
  },
  data() {
    return {
      product: {
        product_id: "",
        name: "",
        price: 0,
        initial_price: 0,
        rate: 0,
        delivery: "",
        url: "",
        affiliate_store_id: "",
      },
      disabled: false,
      is_editing: false,
      category_ids: "",
      data: {},
      image_url: "",
      formKey: "formKey",
      showModalAddCategory: false,
      showModalAddProductTag: false,
    };
  },
  created() {
    if (!this.id) this.$store.commit("product.product_affiliates.detail.purge");
    else {
      this.$store.dispatch("product.product_affiliates.detail.fetch", this.id);
    }
  },
  computed: {
    ...mapGetters({
      detail: "product.product_affiliates.detail",
    }),
    is_edit() {
      if (!this.id) {
        return false;
      }
      return !!this.id;
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  watch: {
    detail: {
      immediate: true,
      handler(product) {
        this.image_url = product.image_url;
        this.data = product;
        this.product.name = product.name;
        this.product.url = product.url;
      },
    },
    category() {
      this.data = this.getInput();
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.formKey = "" + this.lodash.random(10000, 100000);
    },
    create() {
      this.disabled = true;
      let data = {
        ...this.product,
        affiliate_store_id: this.affiliateStoreId
          ? this.affiliateStoreId
          : this.product.affiliate_store_id,
        image_url: this.image_url ? this.image_url : undefined,
        "category_ids[0]": this.category_ids,
      };
      const dataClean = this.lodash.omitBy(data, this.lodash.isNil);
      this.$store
        .dispatch("product.product_affiliates.create", dataClean)
        .then((data) => {
          if (this.affiliateStoreId) {
            this.$emit("created", data);
            this.reset();
            this.$emit("update:show", false);
          } else this.$router.push({ path: `${data.id}` });
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    save() {
      this.disabled = true;
      let data = {
        ...this.lodash.pick(this.data, ["name", "rate", "url"]),
        ...this.product,
        image_url: this.image_url ? this.image_url : undefined,
      };
      const dataClean = this.lodash.omit(data, [
        "product_id",
        "affiliate_store_id",
      ]);
      this.$store
        .dispatch("product.product_affiliates.detail.update", dataClean)
        .then((data) => {
          this.$emit("update:show", false);
          this.$emit("created", data);
        })
        .finally(() => {
          this.disabled = false;
          this.is_editing = false;
        });
    },
    imgUrlChange(e) {
      this.image_url = e;
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return { ...this.detail };
    },
  },
};
</script>
