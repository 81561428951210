<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="Category" bold />
      <TSpinner :loading="loading" />
      <TButton
        class="ml-auto"
        icon="cil-plus"
        content="Add category"
        @click="showModalCate = true"
      />
    </CCardHeader>
    <CCardBody class="mb-2 w-100 mx-auto" style="max-width: 800px">
      <TTableAdvance
        :items="list"
        :fields="itemFields"
        store="product.category_affiliates"
        resource=""
        noFilter
        reloadable
        @click-reload="fetchCategories"
      >
        <template #append-actions="{ item }">
          <CCol class="d-flex px-2" col="12">
            <TButtonEdit @click="editItem(item)" />
            <TButtonDelete @click="removeCateAffi(item.id)" />
          </CCol>
        </template>
        <template #image_url="{ item }">
          <td>
            <CImg
              style="height: 50px; width: 50px; object-fit: contain"
              thumbnail
              :src="item.image_url"
              placeholderColor
              class="mb-3 p-0"
            />
          </td>
        </template>
        <template #category="{ item }">
          <td>
            <SMessageRole :id="item.name" />
          </td>
        </template>
        <template #parent="{ item }">
          <td>
            <TMessageText
              v-if="item.parent"
              :value="item.parent.name"
              noTranslate
            />
          </td>
        </template>
      </TTableAdvance>
    </CCardBody>
    <CategoryModal
      v-if="id"
      :show.sync="showModalCate"
      :affiliateStoreId="id"
      @created="fetchCategories"
    />
    <CategoryModal
      v-if="id"
      @updated="fetchCategories()"
      :show.sync="showEditCategory"
      :affiliateStoreId="id"
      :category="detail"
    />
  </CCard>
</template>

<script>
import CategoryModal from "./CategoryModal.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CategoryModal,
  },
  data() {
    return {
      key: "key",
      keyRole: "keyRole",
      category_id: "",
      itemFields: [
        {
          key: "_",
          label: "#",
          _style: "width: 40px",
        },
        {
          key: "image_url",
          label: "Featured image",
          _style: "width: 50px",
          placeholder: "Description",
        },
        {
          key: "category",
          label: "Category",
          _style: "min-width: 150px",
          placeholder: "Description",
        },
        {
          key: "parent",
          label: "Parent category",
          _classes: "text-nowrap",
          _style: "width: 35%",
        },
      ],
      showModalCate: false,
      showEditCategory: false,
    };
  },
  created() {
    this.fetchCategories();
  },
  computed: {
    ...mapGetters({
      list: "product.category_affiliates.list",
      detail: "product.category_affiliates.detail",
      loading: "product.category_affiliates.loading",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    fetchCategories() {
      this.$store.dispatch("product.category_affiliates.apply-query", {
        "filter[affiliate_store_id]": this.id,
      });
    },
    removeCateAffi(id) {
      this.$store.dispatch("product.category_affiliates.delete", id);
    },
    editItem(item) {
      this.$store.commit("product.category_affiliates.select", item.id);
      this.showEditCategory = true;
    },
  },
  destroyed() {
    this.$store.commit("product.category_affiliates.purge");
  },
};
</script>
